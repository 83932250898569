/* eslint-disable max-len */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-quotes */
/* eslint-disable comma-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React, {
  useState, useEffect, useContext,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/search/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/toast/style.scss';
import SSRIcon from '@ingka/ssr-icon';
import information from '@ingka/ssr-icon/paths/information-circle';
import negativeFlowMsg from '../messages/negativeFlowMessages.json';
import SearchBarComponent from '../components/searchpage/searchBar/SearchBarComponent';
import Filtercomponent from '../components/searchpage/filters/Filtercomponent';
import Resultscomponent from '../components/searchpage/results/Resultscomponent';
import {
  retrieveSearchData,
  retrieveSearchCount,
  retrieveItemsCount,
  retrieveMultipleSearchData,
  clearResultsData,
} from '../api/Apirequests';
import ToastMessage from '../common-components/toastMessage/ToastMessage';
import Loadingcomponent from '../common-components/loader/Loadingcomponent';
import Diplayfilters from '../components/searchpage/filters/Diplayfilters';
import { selectedPopupValue } from '../App';
import ErrorHandler from '../common-components/errorhandler/ErrorHandler';
import SitsContext from '../context/SitsContext';

let searchValue = null;
let searchResultsAlternate = {
  search_text: null,
  source_locale: null,
  target_locale: null,
  data: [],
};

export let messagetobedisplayed = [];
export let correlationid = [];

export default function Searchpage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const sitsContext = useContext(SitsContext);
  const [Searchresults, setSearchresults] = useState({ results: [] });
  //  intializing an array for search results
  const [noresults, setNoresults] = useState();
  // intializing the no results for capturing the count 0 in the search results
  const [nodisplay, setNodisplay] = useState(false);
  // intializing the nodisplay to disable the search results
  const [selectedfilters, setSelectedfilters] = useState([]);
  //  intializing an array for selected filters
  const [selectedfiltersvalue, setSelectedfiltersvalue] = useState([]);
  //  intializing an array for selected filters name
  const [searchingdata, setSearchingdata] = useState({
    search_value: null,
    searchclick: false,
    selected_locale_code: null,
  }); //  intializing an array for searchbar data
  const [isLoading, setLoading] = useState(false);
  // intializing the loading to show the loading component
  const [loadingRedirect, setLoadingRedirect] = useState(false);
  const [loadingcount, setLoadingcount] = useState();
  // to show the loading skeleton for the count
  // eslint-disable-next-line no-unused-vars
  const [loadingText, setLoadingText] = useState('Loading your results');
  const [pagedata, setPagedata] = useState(1);
  // intializing the page data to capture the page number
  const [previousvalue, setPreviousvalue] = useState();
  // intializing the previou value to capture the previous searched value
  const [cleared, setCleared] = useState();
  // intializing the cleared to capture the cleared filters value
  const [clearedvalues, setClearedvalues] = useState();
  // intialing the clearedvalues to capture the cleared filters name
  const [totalcount, setTotalcount] = useState();
  // intializing the total count to capture the total search results count
  const [objItemCount, setObjItemCount] = useState([]);
  const [statusItemCount, setStatusItemCount] = useState([]);
  const [userIdCount, setUserIdCount] = useState([]);
  const [filtersearch, setFiltersearch] = useState(false);
  // intializing the Filtersearch to capture the search action in the filter dropdown
  const [filterheight, setFilterheight] = useState();
  // intializing the height to capture the height of the display filters component
  const [errorText, setErrorText] = useState('');
  // intializing the error text to capture the erroe message from the api
  const [selectedfiltersforapi, setSelectedfiltersforapi] = useState({
    Objecttypefilters: [],
    Statusfilters: [],
    DateFilters: []
  });
  const [isAllValuesCleared, setIsAllValuesCleared] = useState(false);
  const [enableerrorhandler, setEnableerrorhandler] = useState(false);
  const [editPageErrorhandler, setEditPageErrorhandler] = useState(false);
  // intializing the enableerrorhandler  to enable the error handler component
  // to show the error messsage
  const [showToastMessage, setShowToastMessage] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [langvalue, setlangvalue] = useCookies(['selected_locale_Name']);
  let page = ''; // to capture the page number
  const target_locale = selectedPopupValue.selectedlangvalue;
  //  user selected lang_code from the popup is assigned to target locale.
  // Results table will not be displayed when there is change in target_locale
  useEffect(() => {
    if ((sitsContext.currentTargetLocale !== null
      && sitsContext.currentTargetLocale !== undefined)) {
      if (sitsContext.currentTargetLocale !== target_locale) {
        setNodisplay(true);
        setPagedata(1);
        sitsContext.updateCurrentTargetLocale(target_locale);
        sitsContext.updateTabNavigation('tab_0');
        sitsContext.updateEnableSearchbuttoninfilter(false);
        sitsContext.updateintialfiltervalue(false);
        if (sitsContext.checkInitState) {
          sitsContext.updateFilterDisplayFlag(false);
          sitsContext.updateInitialFilterFlag(false);
          sitsContext.updatePageRedirection(true);
        }
        sitsContext.updateCurrentStatePageName('dashboard');
        sitsContext.resetDashboardPageData();
        sitsContext.resetSearchPageData();
        sitsContext.updateToastMessage(true, `${langvalue.selected_locale_Name} loaded successfully!`);
        navigate('/Dashboardpage', { state: { path: 'dashboardpage', localeChange: true } });
      }
    } else {
      sitsContext.updateCurrentTargetLocale(target_locale);
    }
  }, [target_locale]);

  useEffect(() => {
    if (showToastMessage) {
      setTimeout(() => {
        setShowToastMessage(false);
      }, 3000);
    }
  }, [showToastMessage]);

  // On the change of target_locale, below logic will be executed for displaying
  // count for each items in the filters
  useEffect(() => {
    if (target_locale && (!sitsContext.pageRedirection)) {
      const initialSearchData = {
        search_value:
          searchingdata.search_value === undefined
            ? null
            : searchingdata.search_value,
        selected_locale_code:
          searchingdata.selected_locale_code === undefined
            ? null
            : searchingdata.selected_locale_code,
      };
      const initialSelectedFilters = {
        Objecttypefilters:
          selectedfiltersforapi.Objecttypefilters === undefined
          || selectedfiltersforapi.Objecttypefilters.length === 0
            ? []
            : selectedfiltersforapi.Objecttypefilters,
        Statusfilters:
          selectedfiltersforapi.Statusfilters === undefined
          || selectedfiltersforapi.Statusfilters.length === 0
            ? []
            : selectedfiltersforapi.Statusfilters,
      };
      setSearchingdata(initialSearchData);
      setSelectedfiltersforapi(initialSelectedFilters);
      retrieveItemsCount(
        searchingdata,
        selectedfiltersforapi,
        target_locale,
        SetInitialItemsCountHandler,
      );
    }
  }, [target_locale]);

  useEffect(() => {
    if (isAllValuesCleared) {
      retrieveItemsCount(
        searchingdata,
        selectedfiltersforapi,
        target_locale,
        SetInitialItemsCountHandler,
      );
      setIsAllValuesCleared(false);
    }
  }, [isAllValuesCleared]);

  useEffect(() => {
    if (location.state !== null && sitsContext.pageRedirection) {
      // Defaulting the Matches count value back to null
      sitsContext.updateMatchesSuggestionsCount(null);
      if ((location.state.buttonClicked !== 'release') && (location.state.buttonClicked !== 'closeUpdate')) {
        if (location.state.buttonClicked === 'error') {
          correlationid = location.state.correlationId;
          setEditPageErrorhandler(true);
        }
        setFilterheight(sitsContext.previousStateFilterHeight);
        setSearchresults(sitsContext.previousStateData);
        setNodisplay(false);
        if (typeof sitsContext.previousStateCount === 'undefined') {
          setNoresults(0);
        } else {
          setNoresults(sitsContext.previousStateCount);
        }
        setTotalcount(sitsContext.previousStateCount);
        setLoadingRedirect(true);
        setTimeout(() => {
          setObjItemCount(sitsContext.previousStateItemsCount.objectItemCount);
          setStatusItemCount(sitsContext.previousStateItemsCount.statusItemCount);
          setUserIdCount(sitsContext.previousStateItemsCount.useridItemCount);
          setSelectedfilters(
            sitsContext.previousStateSelectedFilter.selectedFiltersName
          ); // assigning the filtersname
          setSelectedfiltersvalue(
            sitsContext.previousStateSelectedFilter.selectedFiltersShortname
          ); // assigning the filtersvalue
          setSelectedfiltersforapi(sitsContext.previousStateSelectedFilter.selectedFiltersAll);
          setLoadingRedirect(false);
        }, 1000);
      } else if ((location.state.buttonClicked === 'release'
        && (sessionStorage.getItem('function') === 'release'))
        || (location.state.buttonClicked === 'closeUpdate'
        && (sessionStorage.getItem('function') === 'closeUpdate'))) {
        sessionStorage.removeItem('function');
        setLoadingRedirect(true);
        setTimeout(() => {
          retrieveMultipleSearchData(
            sitsContext.previousSearchData,
            sitsContext.previousStateSelectedFilter.selectedFiltersAll,
            sitsContext.previousStatePageNumber,
            target_locale,
            updateSearchResultsHandler,
          );
        }, 3000);
      }
    }
  }, []);

  const updateSearchResultsHandler = (searchResultsData) => {
    searchResultsAlternate = {
      ...searchResultsAlternate,
      search_text: searchResultsData.search_text,
      source_locale: searchResultsData.source_locale,
      target_locale: searchResultsData.target_locale,
      data: [...searchResultsAlternate.data, ...searchResultsData.data],
    };
    retrieveItemsCount(
      sitsContext.previousSearchData,
      sitsContext.previousStateSelectedFilter.selectedFiltersAll,
      target_locale,
      setOtherStateCallbackHandler,
    );
  };

  const setOtherStateCallbackHandler = (objCount, statusCount, useridCount) => {
    clearResultsData();
    sitsContext.updatePreviousItemsCount(objCount.data, useridCount.data, statusCount.data);
    setFilterheight(sitsContext.previousStateFilterHeight);
    setSearchresults(searchResultsAlternate);
    setNodisplay(false);
    setTimeout(() => {
      setObjItemCount(objCount.data);
      setStatusItemCount(statusCount.data);
      setUserIdCount(useridCount.data);
      setSelectedfilters(
        sitsContext.previousStateSelectedFilter.selectedFiltersName
      ); // assigning the filtersname
      setSelectedfiltersvalue(
        sitsContext.previousStateSelectedFilter.selectedFiltersShortname
      ); // assigning the filtersvalue
      setSelectedfiltersforapi(sitsContext.previousStateSelectedFilter.selectedFiltersAll);
      setLoadingRedirect(false);
      searchResultsAlternate = {
        ...searchResultsAlternate,
        search_text: null,
        source_locale: null,
        target_locale: null,
        data: [],
      };
      callSearchCountDetail();
    }, 1000);
  };

  const callSearchCountDetail = () => {
    retrieveSearchCount(
      sitsContext.previousSearchData,
      sitsContext.previousStateSelectedFilter.selectedFiltersAll,
      target_locale,
      setTotalCountHandler,
    );
  };

  const setTotalCountHandler = (data) => {
    setNoresults(data.totalResultCount);
    setTotalcount(data.totalResultCount);
    sitsContext.updateTotalCount(data.totalResultCount);
    if (location.state.buttonClicked === 'release') {
      setTimeout(() => {
        setShowToastMessage(true);
      }, 3000);
    }
  };

  // Workaround for delay in getting response from backend. This method should be removed
  // Once delay issue has bene resolved
  const SetInitialItemsCountHandler = (objCount, statusCount, useridCount) => {
    if (searchValue === null) {
      setObjItemCount(objCount.data);
      setStatusItemCount(statusCount.data);
      setUserIdCount(useridCount.data);
    } else {
      searchValue = null;
    }
  };

  // Updating the count for each items in their respective state
  const SetItemsCountHandler = (objCount, statusCount, useridCount) => {
    setObjItemCount(objCount.data);
    setStatusItemCount(statusCount.data);
    setUserIdCount(useridCount.data);
    sitsContext.updatePreviousItemsCount(objCount.data, useridCount.data, statusCount.data);
  };

  // Updating the count for each item when user clears the search text (by clicking close icon)
  const getItemsCountOnClearingHandler = () => {
    const searchBarDataOnClearing = {
      ...searchingdata,
      search_value: null,
      searchclick: false,
    };
    searchValue = null;
    sitsContext.resetSearchPageData();
    setSearchingdata(searchBarDataOnClearing);
    setSelectedfilters([]);
    setSelectedfiltersvalue([]);
    setSelectedfiltersforapi({
      ...selectedfiltersforapi,
      Objecttypefilters: [],
      Statusfilters: [],
    });
    setIsAllValuesCleared(true);
  };

  // assigning the Api response to search results
  const Searchoutput = (data) => {
    if (data.status >= 400 && data.status !== 200) {
      setEnableerrorhandler(true); // Enabling the error handing
      setNodisplay(true); // clearing the searchresults
      messagetobedisplayed = 1;
      correlationid = data.data.CorrelationID;
      setErrorText(data.statusText); // assigning the error status text
    } else if (data.data.length !== 0) {
      setSearchresults(data);
    } // assigning the Searchresults
    setNodisplay(false);
    setLoading(false);
  };

  // assigning the Api response to search results count
  const Searchcount = (data) => {
    if (data.status >= 400 && data.status !== 200) {
      setEnableerrorhandler(true); // Enabling the error handing
      setNodisplay(true); // clearing the searchresults
      messagetobedisplayed = 2;
      correlationid = data.data.CorrelationID;
      setErrorText(data.statusText); // assigning the error status text
    } else {
      sitsContext.updateTotalCount(data.totalResultCount);
      setNoresults(data.totalResultCount);
      setTotalcount(data.totalResultCount);
    }
    setNodisplay(false);
    setLoadingcount(false);
  };

  // search results Api call Funtion
  const apicall = (searchingdata, selectedfiltersforapi, pagedata) => {
    retrieveSearchData(
      searchingdata,
      selectedfiltersforapi,
      pagedata,
      target_locale,
      Searchoutput,
    ); // Api request
  };

  // search count Api call Funtion
  const countapi = (searchingdata, selectedfiltersforapi) => {
    setLoadingcount(true);
    retrieveSearchCount(
      searchingdata,
      selectedfiltersforapi,
      target_locale,
      Searchcount,
    ); // Count Api request
  };

  // Search count for each items in all filters function
  const getItemCount = (searchingdata, selectedfiltersforapi) => {
    retrieveItemsCount(
      searchingdata,
      selectedfiltersforapi,
      target_locale,
      SetItemsCountHandler,
    );
  };

  // creating the searchbar data array
  const Searchdata = (SearchbarData) => {
    setSearchingdata(SearchbarData); // assigning the Searchbar data
    sitsContext.updatePreviousSearchData(SearchbarData);
    setPreviousvalue(SearchbarData.search_value);
    if (previousvalue !== SearchbarData.search_value) {
      page = 1; // pagenumber updated as value 1
      setPagedata(page);
    } else {
      page = pagedata;
    }
    if (
      SearchbarData.searchclick === true
      && SearchbarData.search_value !== null
    ) {
      sitsContext.updateCheckInitState(true);
      searchValue = SearchbarData.search_value;
      setNodisplay(false);
      countapi(SearchbarData, selectedfiltersforapi); // search count api call
      getItemCount(SearchbarData, selectedfiltersforapi);
      // To get count for each items in the filters
      setLoading(true); // Loading component is displayed
      apicall(SearchbarData, selectedfiltersforapi, page);
    } // search results api call
  };

  // creating the selected filters array
  const Selectedvalues = (value) => {
    if (value.selectedfilters.length === 0 && searchingdata.search_value === null
      && sitsContext.initialStateFilterFlag
      && sitsContext.previousSearchData.search_value === null) {
      setNodisplay(true);
      setSelectedfilters([]); // assigning the filtersname
      setSelectedfiltersvalue([]); // assigning the filtersvalue
      setSelectedfiltersforapi([]);
      sitsContext.updateCheckInitState(false);
      sitsContext.updatePageRedirection(false);
      sitsContext.updateTotalCount(undefined);
      sitsContext.updateEnableSearchbuttoninfilter(false);
      retrieveItemsCount(
        searchingdata,
        [],
        target_locale,
        SetInitialItemsCountHandler,
      );
    } else {
      page = 1;
      if (sitsContext.enablesearchbuttoninfilter === false
      && sitsContext.previousStateSelectedFilter.selectedFiltersName.length !== 0
      && searchingdata.search_value === null && value.searchselected === undefined) {
        sitsContext.updateEnableSearchbuttoninfilter(true);
      }
      setFiltersearch(value.searchselected);
      const searchvalue = sitsContext.enablesearchbuttoninfilter;
      setSelectedfilters(value.selectedfiltersName); // assigning the filtersname
      setSelectedfiltersvalue(value.selectedfilters); // assigning the filtersvalue
      setSelectedfiltersforapi(value.selectedfilterstoapi);
      if (sitsContext.initialStateFilterFlag) {
        sitsContext.updatePreviousSelectedFilter(
          value.selectedfiltersName,
          value.selectedfilters,
          value.selectedfilterstoapi,
        );
      }
      if (sitsContext.initialStateFilterFlag) {
        if (searchingdata.searchclick === true
       || (searchvalue === true && value.selectedfilters.length !== 0)) {
          if (nodisplay === false || (searchvalue === true && value.selectedfilters.length !== 0)) {
            sitsContext.updateCheckInitState(true);
            if (searchingdata.search_value === null && searchingdata.searchclick === false) {
              countapi(sitsContext.previousSearchData, value.selectedfilterstoapi);
              // search count api call
              getItemCount(sitsContext.previousSearchData, value.selectedfilterstoapi);
              setLoading(true); // Loading component is displayed
              apicall(sitsContext.previousSearchData, value.selectedfilterstoapi, page);
              // search results api call
            } else {
              countapi(searchingdata, value.selectedfilterstoapi); // search count api call
              getItemCount(searchingdata, value.selectedfilterstoapi);
              setLoading(true); // Loading component is displayed
              apicall(searchingdata, value.selectedfilterstoapi, page);
            }
          }
        } else if (sitsContext.initialStateFilterFlag
      && (sitsContext.previousSearchData.searchclick === true || searchvalue === true)) {
          if (nodisplay === false || searchvalue === true) {
            sitsContext.updateCheckInitState(true);
            countapi(
              sitsContext.previousSearchData,
              value.selectedfilterstoapi
            ); // search count api call
            getItemCount(sitsContext.previousSearchData, value.selectedfilterstoapi);
            apicall(
              sitsContext.previousSearchData,
              value.selectedfilterstoapi,
              page
            ); // search results api call
          }
        }
      }
    }
  };

  // creating the cleared filters array
  const displayfilter = (Updatedfilters, Updatedfiltersvalue) => {
    setCleared(Updatedfilters); // cleared filters value
    setClearedvalues(Updatedfiltersvalue); // cleared filters name
    if (Updatedfilters.length !== 0
      && sitsContext.previousStateSelectedFilter.selectedFiltersName.length !== 0
      && (sitsContext.previousSearchData.search_value === null
      || searchingdata.search_value === null)) {
      if (sitsContext.enablesearchbuttoninfilter === false) {
        sitsContext.updateEnableSearchbuttoninfilter(true);
      }
    }
  };

  const searchBarOnClearHandler = () => {
    sitsContext.updatePageRedirection(false);
  };

  // cleared the results and filters
  const ShowResultsGrid = (display) => {
    setNodisplay(display); // results table is cleared
    setSelectedfiltersvalue([]);
  };

  // creating an function to page the page number to api
  const PageNumber = (page) => {
    setNodisplay(false);
    let pagenumber = 0;
    if (page === null) {
      setPagedata(1);
      pagenumber = 1;
    } else {
      setPagedata(page);
      pagenumber = page;
    }
    if (sitsContext.pageRedirection) {
      apicall(
        sitsContext.previousSearchData,
        sitsContext.previousStateSelectedFilter.selectedFiltersAll,
        pagenumber,
      ); // search results api call
    } else {
      apicall(searchingdata, selectedfiltersforapi, pagenumber); // search results api call
    }
  };

  // creating the function to capture the display filter component height
  const heightoffilters = (height) => {
    sitsContext.updatePreviousFilterHeight(height);
    setFilterheight(height);
  };

  const disableErrorModal = () => {
    setEditPageErrorhandler(false);
  };

  return (
    <div className="searchPage_container ">
      {(sitsContext.toastMessage.showToastMessage === true)
         && (
         <ToastMessage />
         )}

      <SearchBarComponent
        Searchdata={(SearchbarData) => Searchdata(SearchbarData)}
        ShowResultsGrid={(display) => ShowResultsGrid(display)}
        filterssearch={filtersearch}
        countOnClearHandler={getItemsCountOnClearingHandler}
        searchBarOnClear={searchBarOnClearHandler}
      />

      <Filtercomponent
        Selectedvalues={(value) => Selectedvalues(value)}
        ResultCount={totalcount}
        ShowCount={noresults !== 0 && noresults !== undefined && !nodisplay}
        filterscleared={cleared}
        filtersclearedvalue={clearedvalues}
        objCount={objItemCount.object_type}
        statusCount={statusItemCount.status}
        userCount={userIdCount.user_id}
        countloading={loadingcount}
        locationValue={location.state}
      />

      {noresults !== undefined && !nodisplay && (
        <hr className="page_divider" />
      )}

      <Diplayfilters
        displayfilter={(Updatedfilters, Updatedfiltersvalue) => displayfilter(
          Updatedfilters,
          Updatedfiltersvalue,
        )}
        heightoffilters={(height) => heightoffilters(height)}
        filters={selectedfilters}
        filtervalue={selectedfiltersvalue}
        clearfilters={nodisplay}
      />

      <div className='search_loading_ball' style={{ display: (isLoading) ? '' : 'none' }}>
        <Loadingcomponent loadingText={loadingText} />
      </div>

      <div className='search_loading_ball' style={{ display: (loadingRedirect) ? '' : 'none' }}>
        <Loadingcomponent loadingText={loadingText} />
      </div>

      {Searchresults.data !== undefined && (
      <div style={{ visibility: loadingRedirect ? 'hidden' : 'visible' }}>
        <div style={{ display: isLoading ? 'none' : '' }}>
          {noresults !== 0 && noresults !== undefined && !nodisplay && (
            <Resultscomponent
              PageNumber={(page) => PageNumber(page)}
              height={filterheight}
              count={totalcount}
              answer={Searchresults}
              bannerheight={props.heightofbanner}
            />
          )}
        </div>
      </div>
      )}

      {/* <Toast text='1 text released' isOpen={true} /> */}

      {noresults === 0 && !nodisplay && !isLoading && (
        <div style={{ display: loadingRedirect ? 'none' : '' }}>
          <div className="empty_grid">
            <SSRIcon className="information_circle_color" paths={information} />
            {negativeFlowMsg.noResultsInsearchPage}
          </div>
        </div>
      )}

      {editPageErrorhandler
        && (
          <div style={{ display: (editPageErrorhandler) ? '' : 'none' }}>
            <ErrorHandler
              errorDisplayed={6}
              disableErrorHandler={disableErrorModal}
              errorNavToEditor={true}
            />
          </div>
        )}

      {enableerrorhandler && !nodisplay && !isLoading && (
        <ErrorHandler errorDescription={errorText} />
      )}
    </div>
  );
}
