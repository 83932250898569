/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable import/no-mutable-exports */
import axios from 'axios';
import { defaultSortValueforApi } from '../components/searchpage/filters/Sortfiltercomponent';
import {
  SITS_API_URL, CRED_KEY, CRED_USER, CRED_PASS,
} from '../Config';
import { selectedPopupValue } from '../App';
// import { selectedPage } from '../common-components/header/Header';

const CryptoJS = require('crypto-js');

let gObjectTypeValues = []; //  intializing an array for the ObjectType Filters
let gStatusTypeValues = []; //  intializing an array for the Status Filters
let gUserIdTypeValues = []; //  intializing an array for the User ID Filters
let gCategoryDataValues = []; //  intializing an array for the Category Filters
let gObjectTypeValuesError = []; // array for capturing object type api error
let gStatusTypeValuesError = []; // array for capturing status api error
let gUserIdTypeValuesError = []; // array for capturing userid api error
let gCategoryDataValuesError = []; // array for capturing category api error
let userIdDetails = [];
// eslint-disable-next-line no-unused-vars
// Function to retrieve token and add it in a request
let searchResultsTotal = {
  search_text: null,
  source_locale: null,
  target_locale: null,
  data: [],
};
// eslint-disable-next-line no-unused-vars
let saveApiCallCount = 0;
const retrieveToken = () => {
  const bytes = CryptoJS.AES.decrypt(
    window.sessionStorage.getItem('token'),
    CRED_KEY,
  );
  // CryptoJS.HmacSHA256();
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  const config = {
    headers: {
      Authorization: `Bearer ${decryptedData}`,
    },
  };

  return config;
};

// const generateSecretKey = () => {
//   const keyLength = 16; // 32 bytes = 256 bits (AES-256)
//   const buffer = new Uint8Array(keyLength);
//   crypto.getRandomValues(buffer);
//   return Array.from(buffer, (byte) => byte.toString(16).padStart(2, '0')).join('');
// };

// API Request to get a token from backend
const getAuthToken = async () => {
  const userCred = {
    headers: {
      username: CRED_USER,
      password: CRED_PASS,
    },
  };

  return axios
    .post(`${SITS_API_URL}/api/Login/GetJWTToken`, null, userCred)
    .then((response) => {
      const token = CryptoJS.AES.encrypt(
        JSON.stringify(response.data.token),
        CRED_KEY,
      ).toString();

      window.sessionStorage.setItem('token', token);
    });
};

// API request to retrieve Objecttype filters
const retrieveObjectType = async () => {
  const config = retrieveToken();
  return axios
    .get(`${SITS_API_URL}/api/Filter/GetObjectType`, config)
    .then((response) => {
      gObjectTypeValues = response.data; // assigning the response data to objecttypelist array
    }).catch((errors) => {
      if (errors.response) {
        gObjectTypeValuesError = errors.response; // Error response is assign to successmethod
      } else if (errors.request) {
        gObjectTypeValuesError = errors.request; // Error request is assign to successmethod
      } else {
        gObjectTypeValuesError = errors.message; // Error message is assign to successmethod
      }
    });
};

// API request to retrieving the search results
const retrieveMultipleSearchData = async (
  searchingData,
  selectedFiltersForApi,
  pageData,
  target_locale,
  // eslint-disable-next-line no-unused-vars
  getSearchData,
) => {
  const config = retrieveToken();
  for (let i = 1; i <= pageData; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    await axios
      .post(`${SITS_API_URL}/api/Search/SearchResults`, getPayloadData(searchingData, selectedFiltersForApi, i, target_locale), config)
      // eslint-disable-next-line no-loop-func
      .then((response) => {
        searchResultsTotal = {
          ...searchResultsTotal,
          search_text: response.data.search_text,
          source_locale: response.data.source_locale,
          target_locale: response.data.target_locale,
          data: [...searchResultsTotal.data, ...response.data.data],
        };
        if (i === pageData) {
          getSearchData(searchResultsTotal);
        }
      })
      .catch((errors) => errors);
  }
};

// eslint-disable-next-line no-multi-assign, no-undef, max-len
const getPayloadData = (searchingData, selectedFiltersForApi, pageNumberIteration, target_locale) => ({
  // eslint-disable-next-line no-undef
  pageNumber: pageNumberIteration,
  pageSize: 100,
  search_value:
    searchingData.search_value === null ? '' : searchingData.search_value,
  source_locale_code: 'en-ZZ',
  target_locale_code: target_locale,
  selected_locale_code:
  searchingData.selected_locale_code === null
    ? 'en-ZZ'
    : searchingData.selected_locale_code,
  object_type: selectedFiltersForApi.Objecttypefilters,
  status: selectedFiltersForApi.Statusfilters,
  sort:
  selectedFiltersForApi.Sortfilters === undefined
  || selectedFiltersForApi.Sortfilters.length === 0
    ? defaultSortValueforApi
    : selectedFiltersForApi.Sortfilters[0],
  updated: {
    fromDate: selectedFiltersForApi.length !== 0 ? (selectedFiltersForApi.DateFilters.length !== 0 ? `${(selectedFiltersForApi.DateFilters.fromDateValue).toISOString().split('T')[0]}T00:00:00.000Z` : '') : '',
    toDate: selectedFiltersForApi.length !== 0 ? (selectedFiltersForApi.DateFilters.length !== 0 ? `${(selectedFiltersForApi.DateFilters.toDateValue).toISOString().split('T')[0]}T23:59:59.999Z` : '') : '',
  },
  user_id: selectedFiltersForApi.useridfilters,
});

// Clear Search results data
const clearResultsData = () => {
  searchResultsTotal = {
    ...searchResultsTotal,
    search_text: null,
    source_locale: null,
    target_locale: null,
    data: [],
  };
};

// API request to retrieving the search results
const retrieveSearchData = async (
  searchingData,
  selectedFiltersForApi,
  pageData,
  target_locale,
  getSearchData,
) => {
  const config = retrieveToken();
  const data = {
    pageNumber: pageData,
    pageSize: 100,
    search_value:
      searchingData.search_value === null ? '' : searchingData.search_value,
    source_locale_code: 'en-ZZ',
    target_locale_code: target_locale,
    selected_locale_code:
    searchingData.selected_locale_code === null
      ? 'en-ZZ'
      : searchingData.selected_locale_code,
    object_type: selectedFiltersForApi.Objecttypefilters,
    status: selectedFiltersForApi.Statusfilters,
    sort:
    selectedFiltersForApi.Sortfilters === undefined
    || selectedFiltersForApi.Sortfilters.length === 0
      ? defaultSortValueforApi
      : selectedFiltersForApi.Sortfilters[0],
    updated: {
      fromDate: selectedFiltersForApi.length !== 0 ? (selectedFiltersForApi.DateFilters.length !== 0 ? `${(selectedFiltersForApi.DateFilters.fromDateValue).toISOString().split('T')[0]}T00:00:00.000Z` : '') : '',
      toDate: selectedFiltersForApi.length !== 0 ? (selectedFiltersForApi.DateFilters.length !== 0 ? `${(selectedFiltersForApi.DateFilters.toDateValue).toISOString().split('T')[0]}T23:59:59.999Z` : '') : '',
    },
    user_id: selectedFiltersForApi.useridfilters,
  };
  axios
    .post(`${SITS_API_URL}/api/Search/SearchResults`, data, config)
    .then((response) => {
      getSearchData(response.data); // The response data is assign to successmethod function
    })
    .catch((errors) => {
      if (errors.response) {
        getSearchData(errors.response); // The Error response is assign to successmethod function
      } else if (errors.request) {
        getSearchData(errors.request); // The Error request is assign to successmethod function
      } else {
        getSearchData(errors.message); // The Error message is assign to successmethod function
      }
    });
};

// API request to retrieving the search results count
const retrieveSearchCount = async (
  searchingData,
  selectedFiltersForApi,
  target_locale,
  success,
) => {
  const config = retrieveToken();
  const data = {
    search_value:
    searchingData.search_value === null ? '' : searchingData.search_value,
    source_locale_code: 'en-ZZ',
    target_locale_code: target_locale,
    selected_locale_code:
    searchingData.selected_locale_code === null
      ? 'en-ZZ'
      : searchingData.selected_locale_code,
    object_type: selectedFiltersForApi.Objecttypefilters,
    status: selectedFiltersForApi.Statusfilters,
    sort:
    selectedFiltersForApi.Sortfilters === undefined
    || selectedFiltersForApi.Sortfilters.length === 0
      ? defaultSortValueforApi
      : selectedFiltersForApi.Sortfilters[0],
    updated: {
      fromDate: selectedFiltersForApi.length !== 0 ? (selectedFiltersForApi.DateFilters.length !== 0 ? `${(selectedFiltersForApi.DateFilters.fromDateValue).toISOString().split('T')[0]}T00:00:00.000Z` : '') : '',
      toDate: selectedFiltersForApi.length !== 0 ? (selectedFiltersForApi.DateFilters.length !== 0 ? `${(selectedFiltersForApi.DateFilters.toDateValue).toISOString().split('T')[0]}T23:59:59.999Z` : '') : '',
    },
    user_id: selectedFiltersForApi.useridfilters,
  };
  axios
    .post(
      `${SITS_API_URL}/api/ResultsCount/GetTotalResultsandObjectCount`,
      data,
      config,
    )
    .then((response) => {
      success(response.data); // The response data is assign to success function
    }).catch((errors) => {
      if (errors.response) {
        success(errors.response); // The Error response is assign to successmethod function
      } else if (errors.request) {
        success(errors.request); // The Error request is assign to successmethod function
      } else {
        success(errors.message); // The Error message is assign to successmethod function
      }
    });
};

// API Request to retrieve the count for each items
const retrieveItemsCount = async (
  searchingData,
  selectedFiltersForApi,
  target_locale,
  success,
) => {
  const config = retrieveToken();
  const objectCountPayload = {
    search_value:
    searchingData.search_value === null ? '' : searchingData.search_value,
    source_locale_code: 'en-ZZ',
    target_locale_code: target_locale,
    selected_locale_code:
    searchingData.selected_locale_code === null
      ? 'en-ZZ'
      : searchingData.selected_locale_code,
    object_type: [],
    status: selectedFiltersForApi.Statusfilters,
    updated: {
      fromDate: selectedFiltersForApi.length !== 0 ? (selectedFiltersForApi.DateFilters.length !== 0 ? `${(selectedFiltersForApi.DateFilters.fromDateValue).toISOString().split('T')[0]}T00:00:00.000Z` : '') : '',
      toDate: selectedFiltersForApi.length !== 0 ? (selectedFiltersForApi.DateFilters.length !== 0 ? `${(selectedFiltersForApi.DateFilters.toDateValue).toISOString().split('T')[0]}T23:59:59.999Z` : '') : '',
    },
    user_id: selectedFiltersForApi.useridfilters,
  };

  const statusCountPayload = {
    search_value:
    searchingData.search_value === null ? '' : searchingData.search_value,
    source_locale_code: 'en-ZZ',
    target_locale_code: target_locale,
    selected_locale_code:
    searchingData.selected_locale_code === null
      ? 'en-ZZ'
      : searchingData.selected_locale_code,
    object_type: selectedFiltersForApi.Objecttypefilters,
    status: [],
    updated: {
      fromDate: selectedFiltersForApi.length !== 0 ? (selectedFiltersForApi.DateFilters.length !== 0 ? `${(selectedFiltersForApi.DateFilters.fromDateValue).toISOString().split('T')[0]}T00:00:00.000Z` : '') : '',
      toDate: selectedFiltersForApi.length !== 0 ? (selectedFiltersForApi.DateFilters.length !== 0 ? `${(selectedFiltersForApi.DateFilters.toDateValue).toISOString().split('T')[0]}T23:59:59.999Z` : '') : '',
    },
    user_id: selectedFiltersForApi.useridfilters,
  };

  const userIdCountPayload = {
    search_value:
    searchingData.search_value === null ? '' : searchingData.search_value,
    source_locale_code: 'en-ZZ',
    target_locale_code: target_locale,
    selected_locale_code:
    searchingData.selected_locale_code === null
      ? 'en-ZZ'
      : searchingData.selected_locale_code,
    object_type: selectedFiltersForApi.Objecttypefilters,
    status: selectedFiltersForApi.Statusfilters,
    updated: {
      fromDate: selectedFiltersForApi.length !== 0 ? (selectedFiltersForApi.DateFilters.length !== 0 ? `${(selectedFiltersForApi.DateFilters.fromDateValue).toISOString().split('T')[0]}T00:00:00.000Z` : '') : '',
      toDate: selectedFiltersForApi.length !== 0 ? (selectedFiltersForApi.DateFilters.length !== 0 ? `${(selectedFiltersForApi.DateFilters.toDateValue).toISOString().split('T')[0]}T23:59:59.999Z` : '') : '',
    },
    user_id: [],
  };

  const itemsCountReqs = [
    `${SITS_API_URL}/api/ResultsCount/GetTotalResultsandObjectCount`,
    `${SITS_API_URL}/api/ResultsCount/StatusResultsCount`,
    `${SITS_API_URL}/api/ResultsCount/UserIdResultsCount`,
  ];

  const objCountRequest = axios.post(
    itemsCountReqs[0],
    objectCountPayload,
    config,
  );
  const statCountRequest = axios.post(
    itemsCountReqs[1],
    statusCountPayload,
    config,
  );
  const userIdRequest = axios.post(
    itemsCountReqs[2],
    userIdCountPayload,
    config,
  );
  Promise.all([objCountRequest, statCountRequest, userIdRequest]).then((response) => {
    success(response[0], response[1], response[2]);
  });
};

// API request to retrieving the userprefernce data
const retrieveUserdata = async (userData, success) => {
  userIdDetails = userData;
  const config = retrieveToken();
  const data = {
    userId: userData,
  };
  axios
    .post(`${SITS_API_URL}/api/userPreference/userPreference`, data, config)
    .then((response) => {
      success(response.data); // The response data is assign to success function
    }).catch((errors) => {
      if (errors.response) {
        success(errors.response);
      } else if (errors.request) {
        success(errors.request); // The Error request is assign to successmethod
      } else {
        success(errors.message); // The Error message is assign to successmethod
      }
    });
};

const retrieveStatus = async () => {
  const config = retrieveToken();
  return axios
    .get(`${SITS_API_URL}/api/Filter/GetTranslationStatus`, config)
    .then((response) => {
      gStatusTypeValues = response.data;
    }).catch((errors) => {
      if (errors.response) {
        gStatusTypeValuesError = errors.response; // Error response is assign to successmethod
      } else if (errors.request) {
        gStatusTypeValuesError = errors.request; // Error request is assign to successmethod
      } else {
        gStatusTypeValuesError = errors.message; // Error message is assign to successmethod
      }
    });
};

const retrieveUserId = async (localeCode) => {
  const config = retrieveToken();
  const data = {
    locale_code: localeCode,
  };
  return axios
    .post(`${SITS_API_URL}/api/Filter/GetUserId`, data, config)
    .then((response) => {
      gUserIdTypeValues = response.data;
    }).catch((errors) => {
      if (errors.response) {
        gUserIdTypeValuesError = errors.response; // Error response is assign to successmethod
      } else if (errors.request) {
        gUserIdTypeValuesError = errors.request; // Error request is assign to successmethod
      } else {
        gUserIdTypeValuesError = errors.message; // Error message is assign to successmethod
      }
    });
};

const navigateToEditPage = async (routingData, getEditorData) => {
  const config = retrieveToken();

  return axios
    .post(`${SITS_API_URL}/api/EditPage/NavigateToEdit`, routingData, config)
    .then((response) => {
      getEditorData(response);
    }).catch((error) => {
      getEditorData(error);
    });
};

// eslint-disable-next-line max-len
const getConnectedProductsInfo = async (connectedProdReqData, getConnectedProductResponseData) => {
  const config = retrieveToken();

  return axios
    .post(`${SITS_API_URL}/api/LinkedInformation/GetLinkedInformationThroughPI-API`, connectedProdReqData, config)
    .then((response) => {
      getConnectedProductResponseData(response);
    }).catch((error) => {
      getConnectedProductResponseData(error);
    });
};

const clearCountApiCalls = () => {
  saveApiCallCount = 0;
};

// eslint-disable-next-line max-len
const saveTranslation = async (updatedTextData, translationSavedCallbackHandler, confirmedTextId) => {
  const config = retrieveToken();
  const data = updatedTextData;
  saveApiCallCount += 1;
  return axios
    .post(`${SITS_API_URL}/api/EditPage/SaveEditedText`, data, config)
    .then((response) => {
      translationSavedCallbackHandler(response, confirmedTextId);
    }).catch((error) => {
      translationSavedCallbackHandler(error, confirmedTextId);
    });
};

// eslint-disable-next-line max-len
const saveTranslationInTm = async (updatedTextData, translationSavedTmCallbackHandler, confirmedTextId) => {
  const config = retrieveToken();

  return axios
    .post(`${SITS_API_URL}/api/TranslationUnit/TranslationUnit`, updatedTextData, config)
    .then((response) => {
      translationSavedTmCallbackHandler(response, confirmedTextId);
    }).catch((error) => {
      translationSavedTmCallbackHandler(error, confirmedTextId);
    });
};

const releaseTranslatedTexts = async (textIdstorelease, callBackforreleasetext) => {
  const config = retrieveToken();
  const data = {
    texts: textIdstorelease,
    localeCode: selectedPopupValue.selectedlangvalue,
    status: 'Released',
    translatedBy: userIdDetails,
    updatedOn: new Date().toUTCString(),
  };
  return axios
    .post(`${SITS_API_URL}/api/EditPage/ReleaseTexts`, data, config)
    .then((response) => {
      callBackforreleasetext(response);
    }).catch((error) => {
      callBackforreleasetext(error);
    });
};

const concordanceSearch = async (searchedData, concResultsCallback) => {
  const config = retrieveToken();
  const data = searchedData;

  return axios
    .post(`${SITS_API_URL}/api/ConcordanceContoller/ConcordanceSearch`, data, config)
    .then((response) => {
      concResultsCallback(response);
    });
};

const getHistoryInfo = async (historyData, historyDataCallbackHandler) => {
  const config = retrieveToken();

  return axios
    .post(`${SITS_API_URL}/api/History/History`, historyData, config)
    .then((response) => {
      historyDataCallbackHandler(response);
    }).catch((error) => {
      historyDataCallbackHandler(error);
    });
};

const getMatchesSuggestions = async (matchesPayloadData, matchesSuggestionsCallbackHandler) => {
  const config = retrieveToken();

  return axios
    .post(`${SITS_API_URL}/api/TM/TMSearchText`, matchesPayloadData, config)
    .then((response) => {
      matchesSuggestionsCallbackHandler(response);
    }).catch((error) => {
      matchesSuggestionsCallbackHandler(error);
    });
};

// API request to retrieve Objecttype filters
const retrieveCategoryData = async () => {
  const config = retrieveToken();
  return axios
    .get(`${SITS_API_URL}/api/Filter/GetCategory`, config)
    .then((response) => {
      response.data.splice(57, 0, { category: 'Uncategorised' });
      gCategoryDataValues = response.data; // assigning the response data to CategoryDatalist array
    }).catch((errors) => {
      if (errors.response) {
        gCategoryDataValuesError = errors.response; // Error response is assign to successmethod
      } else if (errors.request) {
        gCategoryDataValuesError = errors.request; // Error request is assign to successmethod
      } else {
        gCategoryDataValuesError = errors.message; // Error message is assign to successmethod
      }
    });
};

export {
  retrieveObjectType,
  getAuthToken,
  gObjectTypeValues,
  gObjectTypeValuesError,
  retrieveSearchData,
  retrieveMultipleSearchData,
  clearResultsData,
  retrieveSearchCount,
  retrieveItemsCount,
  retrieveUserdata,
  retrieveStatus,
  retrieveUserId,
  navigateToEditPage,
  getConnectedProductsInfo,
  clearCountApiCalls,
  saveTranslation,
  saveTranslationInTm,
  concordanceSearch,
  getHistoryInfo,
  getMatchesSuggestions,
  releaseTranslatedTexts,
  retrieveCategoryData,
  gStatusTypeValues,
  gStatusTypeValuesError,
  gUserIdTypeValues,
  gUserIdTypeValuesError,
  gCategoryDataValues,
  gCategoryDataValuesError,
};
